import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Hero, Intro } from "../../components/StandartPage/index"
import { Services } from "../../components/Sections/index"
import BlogCard from "../../components/PostCard/PostCardGroup"
import Seo from "../../components/seo"

const CalismaAlanlariPage = ({ data: { blog } }) => (
  <Fragment>
    <Seo
      title="Çalışma Alanlarımız"
      description="Bilişim, Sosyal Medya ve teknoloji avukatı ihtiyaçlarınıza anında cevap veriyoruz."
    />
    <Hero
      title="Çalışma Alanlarımız"
      subtitle="Bilişim ve teknoloji avukatı ihtiyacınıza cevap veriyoruz."
    />
    <Intro
      title="Bilişim Avukatı &
      Bilişim Davaları"
      description="BT ve Teknoloji Avukatları, Her büyüklükteki teknoloji şirketi için uzman hukuki danışmanlık sunuyoruz. Uzman teknolojimiz ve bilişim avukatlarımız, yasal gereksinimlerinize yardımcı olacak zengin bir deneyime sahiptir."
    />
    <Services />
    <BlogCard posts={blog.edges} />
  </Fragment>
)

export default CalismaAlanlariPage

export const blogQuery = graphql`
  {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tags
            title
            description
            Image {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
